import { useState, useEffect } from 'react';
import React from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
// import QR from "../assets/images/QR.jpg"
import styles from './../../Component/cardCss/Test.module.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../Context/UserContext';




const Testt = () => {

  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [accounttype, setAccountType] = useState("");
  const [paymentqrimg, setPaymentQrimg] = useState(null);
const [branchname, setBranchname] = useState("")
  const [holdername, setHolderName ]= useState("");
  const [loading, setLoading] = useState(false);
  const [ifsc, setIfsc] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { adminToken } = useAuth();
  const [bankdata, setBankdata] = useState("")

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    switch (type) {
        case "paymentqrimg":
            setPaymentQrimg(file);
            break;
        default:
            break;
    }


}

const handleSubmit = async (e) => {
//   if (!admintoken) {
//     navigate("/userlogin"); // Replace "/login" with your actual login route
//     return;
// }
  e.preventDefault();
  

  
  

  const formData = new FormData();

  formData.append("PaymentQR", paymentqrimg);
  formData.append("accountNumber", accountNo);
  formData.append("bankName", bankName);
  formData.append("accountType", accounttype);
  formData.append("accountHoldersName", holdername)
  formData.append("IFSCCode", ifsc)
  formData.append("branchName", branchname)

  
  try {
    const token = adminToken; // Assuming userToken is accessible here

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
      },
    };
  
    const response = await axios.post('https://dotmoney-bcknd.onrender.com/paymentDetailes', formData, config);
    console.log('Payment submitted', response.data);
   
    setShow(false)
  } catch (error) {
    console.error('Failed', error);
  } finally {
  
  }
};



useEffect(() => {
    const fetchData = async () => {
   
      try {
        const url = 'https://dotmoney-bcknd.onrender.com/GetpaymentDetailes';
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`, // Replace with your actual token
          // Any other headers you need can be added here
        };

        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        console.log(data);
        return data;
      } catch (error) {
        console.error('Error fetching data:', error);
        return null;
      }
    };

    const fetchDataFromEndpoint = async () => {
      const fetchedData = await fetchData();
      if (fetchedData) {
        setBankdata(fetchedData.QR_and_bankDetails);
        console.log(fetchedData.QR_and_bankDetails);
      }
    };

    fetchDataFromEndpoint();
  }, []);
 // handling onwebsite 

 const handleSwitchChange = async (dataId, newStatus) => {
    // Call your API endpoint to update the 'onWebsite' status
   
    try {
      // Make a PUT or POST request to update the status
      const updatedData = await fetch(`https://dotmoney-bcknd.onrender.com/updateOnWebsite/${dataId}`, {
        method: 'PATCH', // Use the appropriate HTTP method
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`
        },
        body: JSON.stringify({ onWebsite: newStatus }),
      });
  
      // Handle the response accordingly
      if (updatedData.ok) {
        // Handle successful update
        // Optionally, update the state or fetch updated data
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle fetch error
      console.error('Error updating data:', error);
    }
  };


  return (
    <div   className={styles.bank_master_wrapper}>
        <div className={styles.bank_master_header}>

       
<div variant="primary" onClick={handleShow} className={styles.button_addplan} style={{"width": "150px", "height": "50px", "whiteSpace": "nowrap"}}>
Add Payments
</div>
</div>

<Modal show={show} onHide={handleClose} animation={false}>
<Modal.Header closeButton>
<Modal.Title>Add Payment Methods</Modal.Title>
</Modal.Header>
<Modal.Body>
<div className={styles.qr_up}>

</div>

<form onSubmit={handleSubmit} className={styles.container} enctype="multipart/form-data">
                <div >
                <label className={styles.form}>
                        Bank Name
                        <select name="bankname"  value={bankName} onChange={(e) => setBankName(e.target.value)}>
                            <option value="BankofBaroda">	Bank of Baroda</option>
                            <option value="BankofIndia">Bank of India</option>
                            <option value="BankofMaharashtra">Bank of Maharashtra</option>
                            <option value="CanaraBank">Canara Bank</option>
                            <option value="CentralBankofIndiar">Central Bank of Indiar</option>
                            <option value="IndianBank">	Indian Bankr</option>
                            <option value="IndianOverseasBank">Indian Overseas Bank</option>
                            <option value="Punjab&SindBank">Punjab & Sind Bank</option>
                            <option value="PunjabNationalBank">Punjab National Bank</option>
                            <option value="StateBankofIndia">State Bank of India</option>
                            <option value="UCOBank">UCO Bank</option>
                            <option value="UnionBankofIndia">Union Bank of India</option>
                            <option value="AxisBankLtd.">Axis Bank Ltd.</option>
                            <option value="BandhanBankLtd.">Bandhan Bank Ltd.r</option>
                            <option value="CSBBankLtd.">CSB Bank Ltd.</option>
                            <option value="CityUnionBankLtd.">City Union Bank Ltd.</option>
                            <option value="DCBBankLtd.">DCB Bank Ltd.</option>
                            <option value="DhanlaxmiBankLtd.">	Dhanlaxmi Bank Ltd.</option>
                            <option value="FederalBankLtd.">Federal Bank Ltd.</option>
                            <option value="HDFCBankLtd">HDFC Bank Ltd</option>
                            <option value="ICICIBankLtd.">	ICICI Bank Ltd.</option>
                            <option value="InduslndBankLtd">Induslnd Bank Ltd</option>
                            <option value="Jammu&KashmirBankLtd.">Jammu & Kashmir Bank Ltd.</option>
                            <option value="KarnatakaBankLtd.">Karnataka Bank Ltd.</option>
                            <option value="KarurVysyaBankLtd.">Karur Vysya Bank Ltd.</option>
                            <option value="KotakMahindraBankLtd">Kotak Mahindra Bank Ltd</option>
                            <option value="NainitalBankLtd.">Nainital Bank Ltd.</option>
                            <option value="RBLBankLtd.">RBL Bank Ltd.</option>
                            <option value="SouthIndianBankLtd.">South Indian Bank Ltd.</option>
                            <option value="TamilnadMercantileBankLtd.">	Tamilnad Mercantile Bank Ltd.</option>
                            <option value="YESBankLtd.">YES Bank Ltd.</option>
                            <option value="IDBIBankLtd.">IDBI Bank Ltd.</option>
                            <option value="AuSmallFinanceBankLimited">Au Small Finance Bank Limited</option>
                            <option value="CapitalSmallFinanceBankLimited">Capital Small Finance Bank Limited</option>
                            <option value="EquitasSmallFinanceBankLimited">Equitas Small Finance Bank Limited</option>
                            <option value="SuryodaySmallFinanceBankLimited">Suryoday Small Finance Bank Limited</option>
                            <option value="UjjivanSmallFinanceBankLimited">	Ujjivan Small Finance Bank Limited</option>
                            <option value="UtkarshSmallFinanceBankLimited">Utkarsh Small Finance Bank Limited</option>
                            <option value="ESAFSmallFinanceBankLimited">	ESAF Small Finance Bank Limited</option>
                            <option value="FincareSmallFinanceBankLimited">Fincare Small Finance Bank Limited</option>
                            <option value="JanaSmallFinanceBankLimited">	Jana Small Finance Bank Limited</option>
                            <option value="NorthEastSmallFinanceBankLimited">North East Small Finance Bank Limited</option>
                            <option value="ShivalikSmallFinanceBankLimited">Shivalik Small Finance Bank Limited</option>
                            <option value="UnitySmallFinanceBankLimited">Unity Small Finance Bank Limited</option>
                            <option value="IndiaPostPaymentsBankLimited">India Post Payments Bank Limited</option>
                            <option value="FinoPaymentsBankLimited">	Fino Payments Bank Limited</option>
                            <option value="Paytm Payments Bank Limited">PaytmPaymentsBankLimited</option>
                            <option value="AirtelPaymentsBankLimited">	Airtel Payments Bank Limited</option>
                            <option value="AndhraPragathiGrameenaBank">Andhra Pragathi Grameena Bank</option>
                            <option value="AndhraPradeshGrameenaVikasBank">	Andhra Pradesh Grameena Vikas Bank</option>
                            <option value="ArunachalPradeshRuralBank">	Arunachal Pradesh Rural Bank</option>
                            <option value="AryavartBank">	Aryavart Bankr</option>
                            <option value="AssamGraminVikashBank">	Assam Gramin Vikash Bank</option>
                            <option value="BangiyaGraminVikasBank">	Bangiya Gramin Vikas Bank</option>
                            <option value="BarodaGujaratGraminBank">Baroda Gujarat Gramin Bank</option>
                            <option value="BarodaRajasthanKshetriyaGraminBank">Baroda Rajasthan Kshetriya Gramin Bank</option>
                            <option value="BarodaUPBank">Baroda UP Bank</option>
                            <option value="ChaitanyaGodavariGrameenaBank">Chaitanya Godavari Grameena Bank</option>
                            <option value="ChhattisgarhRajyaGraminBank">Chhattisgarh Rajya Gramin Bank</option>
                            <option value="DakshinBiharGraminBank">	Dakshin Bihar Gramin Bank</option>
                            <option value="EllaquaiDehatiBank">Ellaquai Dehati Bank</option>
                            <option value="HimachalPradeshGraminBank">	Himachal Pradesh Gramin Bank</option>
                            <option value="J&KGrameenBank">J&K Grameen Bank</option>
                            <option value="other">Other</option>
                          
                        </select>
                      
                        {/* <input
                            type="text"
                            placeholder='Bank Name'
                            name="bankname"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            required
                        /> */}
                    </label>
                    <Form.Check // prettier-ignore
type="switch"
id="custom-switch"
label="QR Enable"
/>

                <label className={styles.form}>
                        Upload QR:
                        <input type="file" name="paymentqrimg" accept=".pdf,.jpg,.png" onChange={(e) => handleFileChange(e, "paymentqrimg")} />
                    </label>
                   

                    <label className={styles.form}>
                        Account Number
                        <input
                            type="tel"
                            name="accountNo"
                            placeholder='Account No.'
                            value={accountNo}
                            onChange={(e) => setAccountNo(e.target.value)}
                        />
                    </label>
                    <label className={styles.form}>
                        Branch Name
                        <input
                            type="tel"
                            name="branchname"
                            placeholder='Branch Name'
                            value={branchname}
                            onChange={(e) => setBranchname(e.target.value)}
                        />
                    </label>

                    {/* <label className={styles.form}>
                        Bank Name
                      
                        <input
                            type="text"
                            placeholder='Bank Name'
                            name="bankname"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                            required
                        />
                    </label> */}
                     

                    <label className={styles.form}>
                        Account Type
                        <input
                            type="text"
                            placeholder='Enter Your Account Type'
                            name="accounttype"
                            value={accounttype}
                            onChange={(e) => setAccountType(e.target.value)}
                            required
                        />
                    </label>

                    <label className={styles.form}>
                        Account Holder's Name
                        <input
                            type="text"
                            name="holdername"
                            placeholder='Account holder Name'
                            value={holdername}
                            onChange={(e) => setHolderName(e.target.value)}
                            required
                        />
                    </label>
                    
                    <label className={styles.form}>
                        IfSc Code
                        <input
                            type="text"
                            name="DOB"
                            value={ifsc}
                            placeholder='Your IFSC Code'
                            onChange={(e) => setIfsc(e.target.value)}
                            required
                        />
                    </label>

            
        
                    <div className={`text-center ${styles.form}`}>
                        <button type="submit" className={styles.btn}>{
                            loading ? 'Loading..' : 'Sumbit'
                        }</button>
                    </div>

                  
                </div>
            </form>


</Modal.Body>
{/* <Modal.Footer>
<Button variant="secondary" onClick={handleClose}>
Close
</Button>
<Button variant="primary" onClick={handleClose}>
Save Changes
</Button>
</Modal.Footer> */}
</Modal>
  <div className={styles.table}>
          <table>
            <thead className={styles.table_header}>
              <tr>
                <th>Payment QR</th>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Date Time</th>
               
                <th>Accoumt Type</th>
                <th>Account Holder Name</th>
                <th>IFSC Code</th>
                <th>On Website</th>
                
               

              </tr>
            </thead>

            <tbody className={styles.table_body}>
                {
                    bankdata? (<>
                      {bankdata.map((data, index) => (
          <tr key={data._id}>
            <td>view</td>
            <td>{data.BankDetails.accountNumber}</td>
            <td>{data.BankDetails.bankName}</td>
               <td>{data.created}</td>
                <td>{data.BankDetails.accountType}</td>
                            <td>{data.BankDetails.accountHoldersName}</td>
                              <td>{data.BankDetails.IFSCCode}</td>
        
           <td>
              <Form.Check
                type="switch"
                id={`custom-switch-${data._id}`}
                label={data.onWebsite ? 'Active' : 'Inactive'}
                checked={data.onWebsite}
                onChange={(e) => {
                    const newStatus = e.target.checked; // true or false based on the switch state
                    handleSwitchChange(data.BankDetails.accountNumber, newStatus);
                    // Optionally, update your local state after the status change
                  }}
                
              />
            </td>
          </tr>
        ))}
                    </>) : (<>loading</>)
                }
          

            </tbody>
          </table>
        </div>
    
  
  </div>
  )
}

export default Testt;