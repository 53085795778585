import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from "../../Component/Headers/header.module.css";
import { useAuth } from '../../Context/UserContext';

const Userlogin = () => {
    const navigate = useNavigate();

    const [mobileNo, setMobileNo] = useState("");
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false); // New loading state

    const { loginUser } = useAuth();

    const handleSendOTP = async (e) => {
        const requestData = {
            mobileNo: mobileNo,
        };

        try {
            setLoading(true); // Set loading to true when sending OTP
            const response = await axios.post('https://dotmoney-bcknd.onrender.com/otpSend', requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("Registration success", response.data);
            setOtpSent(true);
            setError(null);
        } catch (error) {
            console.log("Registration Failed", error);
            setError("Login failed. Please try again.");
        } finally {
            setLoading(false); // Set loading to false when OTP is sent (success or failure)
        }
    };

    const handleLogin = async (e) => {
        const requestedData = {
            otp: otp,
        };

        try {
            setLoading(true); // Set loading to true when attempting login
            const response = await axios.post(`https://dotmoney-bcknd.onrender.com/login/${mobileNo}`, requestedData, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            console.log("success", response.data)
            const userData = response.data.user;
            const userToken = response.data.user.token

            loginUser(userData, userToken);
            navigate("/home");

        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false); // Set loading to false when login attempt finishes (success or failure)
        }
    };

    return (
        <div className={styles.login_main}>
            <div className={styles.login}>
                <div className={styles.login_page}>
                    <h2>
                        <strong> Login</strong> <br />
                        <span> with Mobile Number </span>
                    </h2>
                    <div>
                        <h5>Mobile Number</h5>
                        <input
                            type='text1'
                            placeholder='enter 10 digit mobile number'
                            value={mobileNo}
                            onChange={(e) => setMobileNo(e.target.value)}
                        />
                    </div>

                    {otpSent ? (
                        <div>
                            <div>
                                <label>Enter OTP:</label>
                                <input
                                    type='text1'
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </div>
                        </div>
                    ) : null}

                    {error && <p className={styles.error_message}>{error}</p>}

                    <button
                        className={styles.btn_login}
                        onClick={loading ? null : (otpSent ? handleLogin : handleSendOTP)} // Disable button when loading
                    >
                        {loading ? 'Loading...' : (otpSent ? 'Login' : 'Send OTP')}
                    </button>

                    <div>
                        <p style={{ fontSize: "13px" }} className='text-light'>
                            Not have any account? <a className='text-dark' href="#">Sign up</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Userlogin;
