import { useState } from 'react';
import axios from 'axios';
import styles from "../../Component/Headers/header.module.css"
import { useNavigate } from 'react-router-dom';










const UserSignup = () => {

    const [name, setName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [DOB, setDOB] = useState("");
    const [GST_Number, setGST_Number] = useState("");
    const [aadhaarImage, setAadhaarImage] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        switch (type) {
            case "aadhaarImage":
                setAadhaarImage(file);
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (name === '' || mobileNo === '' || DOB === '') {
            alert('Please fill in all the required fields');
            return;
        }

        if (mobileNo.length !== 10 || isNaN(mobileNo)) {
            alert('Mobile number should be 10 digits long and contain only numbers');
            return;
        }

        const formData = new FormData();

        formData.append("documentImage", aadhaarImage);
        formData.append("name", name);
        formData.append("DOB", DOB);
        formData.append("mobileNo", mobileNo);
        formData.append("documentNo", GST_Number)
        console.log(JSON.stringify(formData))

        try {
            const response = await axios.post('https://dotmoney-bcknd.onrender.com/register', formData);
            console.log('Registration successful', response.data);
            navigate("/userlogin")

        } catch (error) {
            console.error(' Failed', error);
        }
        finally {
            setLoading(false); // Set loading to false when the request is completed (success or failure)
        }
    };


    return (
        <div> <section>
            <div className="container">
                <div className={styles.close}> </div>
                <div >
                    <form onSubmit={handleSubmit} className={styles.container} enctype="multipart/form-data">
                        <div className={styles.card}>
                            <div className={styles.card_title}>
                                <div className='btn-group' >
                                    <h1 style={{ whiteSpace: "nowrap" }}>Sign Up</h1>
                                    {/* <div className={styles.crossbtn} style={{ width: "100px", marginBottom: "10px", marginLeft: "248px" }} onClick={handleSignClose}><img src={cross} /></div> */}
                                </div>
                            </div>

                            <label className={styles.form}>
                                Name:
                                <input
                                    type="text"
                                    placeholder='Name'
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </label>

                            <label className={styles.form}>
                                Mobile Number:
                                <input
                                    type="text"
                                    placeholder='Enter 10 Digit Mobile No.'
                                    name="mobileNo"
                                    value={mobileNo}
                                    onChange={(e) => setMobileNo(e.target.value)}
                                    required
                                />
                            </label>

                            <label className={styles.form}>
                                Date of Birth:
                                <input
                                    type="date"
                                    name="DOB"
                                    value={DOB}
                                    onChange={(e) => setDOB(e.target.value)}
                                    required
                                />
                            </label>

                            <label className={styles.form}>
                                Document Type:
                                <select name="documentType">
                                    <option value="aadhaarImage">Aadhar Card</option>
                                    <option value="pan">PAN Card</option>
                                    <option value="voter">Voter Card</option>
                                    <option value="driving">Driving License</option>
                                    <option value="other">Other</option>
                                </select>
                            </label>

                            <label className={styles.form}>
                                Document No:
                                <input
                                    type="tel"
                                    name="text"
                                    placeholder='document number'
                                    value={GST_Number}
                                    onChange={(e) => setGST_Number(e.target.value)}
                                />
                            </label>

                            <label className={styles.form}>
                                Upload Document:
                                <input type="file" name="aadhaarImage" accept=".pdf,.jpg,.png" onChange={(e) => handleFileChange(e, "aadhaarImage")} />
                            </label>



                            <div className={`text-center ${styles.form}`}>
                                <button type="submit" className={styles.btn}>{
                                    loading ? 'Loading..' : 'Sign Up'
                                }</button>
                            </div>

                            <div className={styles.bottom}>
                                already a user <a href="/usersignup"> <strong> Login</strong> </a>
                            </div>
                        </div>s
                    </form>
                </div>

            </div>

        </section></div>
    )
}

export default UserSignup

