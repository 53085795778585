import React from 'react';
import './Footers.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <div className="footer-item">
                            <h4>Contact us</h4>
                            <li>Phone: +91 9609629526,</li>
                            <li>&emsp;&emsp;&emsp;&nbsp;&nbsp;+91 9907492148,</li>
                            <li>&emsp;&emsp;&emsp;&nbsp;&nbsp;+91 7047233190</li>
                            <li>E-mail: onepay.in@gmail.com</li>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-8 col-sm-6 col-xs-12">
                        <div className="footer-item" style={{paddingLeft:"5px"}}>
                            <h4>About us</h4>
                            <h5>Take Business Services From Our Experienced Staff</h5>
                            <p style={{textAlign:"justify", fontSize:"14px"}}>Since the Telecommunication industry came into being, it has
                                become a vital part of daily life and emerged as a major direct communication channel
                                for the coomon man. Our single platform for telecommunication as well as travel and banking
                                industries makes these services conveniently accessible to all. FAST CALL TECHNOLOGIES is a trusted name
                                in the same domain market. We believe in the best service ro make the platform reliable.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div className="footer-item" style={{paddingLeft:"20px"}}>
                            <h4>Connect with us</h4>
                            <ul style={{ listStyle: "none" }}>
                                <li>Address :<br /> BM Building, Kolkata, Salt lake, 700027</li>
                            </ul>
                        </div>
                        <div className="footer-item">
                            {/* use the map here!!!!! */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

