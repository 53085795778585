import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QR from "../assets/images/QR.jpg"
import styles from './../Component/cardCss/Test.module.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Test = () => {

  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [accounttype, setAccountType] = useState("");
  const [paymentqrimg, setPaymentQrimg] = useState(null);

  const [holdername, setHolderName ]= useState("");
  const [loading, setLoading] = useState(false);
  const [ifsc, setIfsc] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    switch (type) {
        case "paymentqrimg":
            setPaymentQrimg(file);
            break;
        default:
            break;
    }


}

const handleSubmit = async (e) => {
//   if (!userToken) {
//     navigate("/userlogin"); // Replace "/login" with your actual login route
//     return;
// }
  e.preventDefault();
  setLoading(true);
  
  

  const formData = new FormData();

  formData.append("PaymentQR", paymentqrimg);
  formData.append("accountNumber", accountNo);
  formData.append("bankName", bankName);
  formData.append("accountType", accounttype);
  formData.append("accountHoldersName", holdername)
  formData.append("IFSCCode", ifsc)
  console.log(JSON.stringify(formData))

  try {
      const response = await axios.post('https://dotmoney-bcknd.onrender.com/paymentDetailes', formData);
      console.log('Payment sumbitted', response.data);
      navigate("/userlogin")
      setLoading(false)

  } catch (error) {
      console.error(' Failed', error);
  }
  finally {
      setLoading(false); // Set loading to false when the request is completed (success or failure)
  }
};




  return (
    <div>    <Button variant="primary" onClick={handleShow}>
    Scan QR And pay
  </Button>

  <Modal show={show} onHide={handleClose} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>scan QR and Pay</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={styles.qr_up}>
        <div className={styles.qr_amount}> Amount To Pay 299</div>
       <div className={styles.qr_img}>
      <img src={QR} alt="" />
    </div>
    </div>
    
<form onSubmit={handleSubmit} className={styles.container} enctype="multipart/form-data">
                        <div >
                        <label className={styles.form}>
                                Upload Payment Screenshot:
                                <input type="file" name="paymentqrimg" accept=".pdf,.jpg,.png" onChange={(e) => handleFileChange(e, "paymentqrimg")} />
                            </label>
                           

                            <label className={styles.form}>
                                Account Number
                                <input
                                    type="tel"
                                    name="accountNo"
                                    placeholder='Account No.'
                                    value={accountNo}
                                    onChange={(e) => setAccountNo(e.target.value)}
                                />
                            </label>

                            <label className={styles.form}>
                                Bank Name
                                <input
                                    type="text"
                                    placeholder='Bank Name'
                                    name="bankname"
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                    required
                                />
                            </label>

                            <label className={styles.form}>
                                Account Type
                                <input
                                    type="text"
                                    placeholder='Enter Your Account Type'
                                    name="accounttype"
                                    value={accounttype}
                                    onChange={(e) => setAccountType(e.target.value)}
                                    required
                                />
                            </label>

                            <label className={styles.form}>
                                Account Holder's Name
                                <input
                                    type="text"
                                    name="holdername"
                                    placeholder='Account holder Name'
                                    value={holdername}
                                    onChange={(e) => setHolderName(e.target.value)}
                                    required
                                />
                            </label>
                            
                            <label className={styles.form}>
                                IfSc Code
                                <input
                                    type="text"
                                    name="DOB"
                                    value={ifsc}
                                    placeholder='Your IFSC Code'
                                    onChange={(e) => setIfsc(e.target.value)}
                                    required
                                />
                            </label>

                        

                           


                            <div className={`text-center ${styles.form}`}>
                                <button type="submit" className={styles.btn}>{
                                    loading ? 'Loading..' : 'Sumbit'
                                }</button>
                            </div>

                          
                        </div>
                    </form>
   

    </Modal.Body>
    {/* <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button>
    </Modal.Footer> */}
  </Modal>
  
  
  </div>
  )
}

export default Test
