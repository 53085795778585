import React, { useEffect, useState } from 'react';
import styles from './../Component/cardCss/Test.module.css';
import './Accordion.css';

import plus from './../assets/images/add.png';
import minus from './../assets/images/minus.png';
import Airtel from './../assets/images/air.png';
import Jio from './../assets/images/jio1.png';
import Vodafone_idea from './../assets/images/vodaidea.png';
import bsnl from '../assets/images/bsnl1.png';
import Dishtv from './../assets/images/dishtv1.webp';
import Videocon from './../assets/images/videocon.png';
import TataSky from './../assets/images/tatasky.jpg';
import SunDirect from './../assets/images/sun.png';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import Pay from '../Component/pay/Pay';




  
 

function Accordion() {


    const [imageBlob, setImageBlob] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const groupedItems = {};
    const [data, setData] = useState(null);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null)
    const { userToken } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    const { user } = useAuth();
    console.log(user);
    const navigate = useNavigate()



    const [isLogin, setLogin] = useState(true)

    const [isOpen, setIsOpen] = useState(false);

    const [paymentReciept, setPaymentReciept] = useState("");
    const [amount, setAmount] = useState("");
  
    const [transactionid, setTransactionid] = useState("");
  //   const [paymentqrimg, setPaymentQrimg] = useState(null);
  
    const [holdername, setHolderName ]= useState("");
    const [loading, setLoading] = useState(false);
    const [bankDataLoaded, setBankDataLoaded] = useState(false);
  

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  const [bankdata, setBankdata] = useState("")
  
    const handleFileChange = (event, type) => {
      const file = event.target.files[0];
      switch (type) {
          case "paymentReciept":
              setPaymentReciept(file);
              break;
          default:
              break;
      }
  }




  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Assuming you have a token stored in localStorage or retrieved from somewhere
    // Retrieve token from localStorage
  
   
    setLoading(true);

    const formData = new FormData();

    formData.append("paymentReciept", paymentReciept);
    formData.append("amount", amount);
    formData.append("transactionid", transactionid);

    try {
        const response = await axios.post('https://dotmoney-bcknd.onrender.com/paymentDetailes', formData, {
            headers: {
                'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
                'Content-Type': 'multipart/form-data', // Set the content type for FormData
            },
        });

        console.log('Payment submitted', response.data);
        navigate("/userlogin");
    } catch (error) {
        console.error('Failed', error);
    } finally {
        setLoading(false);
    }
};


    console.log(isLogin);





    useEffect(() => {
        // Define the URL of the endpoint
        const endpointURL = 'https://dotmoney-bcknd.onrender.com/getPlan';

        // Make a GET request to the endpoint
        fetch(endpointURL)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log(response);
                return response.json();

            })
            .then((responseData) => {
                // Update the state with the fetched data
                setData(responseData.result);
                console.log(responseData.result)
                responseData.result.forEach((product) => {
                    const provider = product.provider;
                    if (!groupedItems[provider]) {
                        groupedItems[provider] = [];
                    }
                    if (!groupedItems[provider].some((item) => item._id === product._id)) {
                        // Checking if the item with the same ID already exists in the group
                        groupedItems[provider].push(product);
                    }
                });
                // console.log(responseData)


                setItems(Object.keys(groupedItems).map((provider, index) => ({
                    title: provider,
                    content: groupedItems[provider].map((product, subIndex) => (
                        <div key={subIndex}>
                            <div className={styles.wrapper}>
                                <div className={styles.left}>
                                    <div className={styles.logo} key={subIndex}>
                                        {renderProviderLogo(product.provider)}
                                    </div>
                                </div>
                                <div className={styles.center} key={subIndex}>
                                    <div className={styles.up}>
                                        {product.title} <span >data</span>
                                    </div>
                                    <div className={styles.down} key={subIndex}> {product.description}</div>

                                </div>
                                <div className={styles.right} key={subIndex}>
                                    <div className={styles.price}>
                                        <span>₹ </span>{product.amount}
                                    </div>

                                    <div> <button onClick={handleShow} role='button' className={styles.button_buy}>Buy Now</button></div>

                                </div>
                                {/* <span className={styles.top}></span>
                                <span className={styles.right1}></span>
                                <span className={styles.button}></span>
                                <span classN={styles.left1}></span> */}
                            </div>
                        </div>
                    )),
                })))

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);


    function renderProviderLogo(provider) {
        switch (provider) {
            case 'Airtel':
                return <img src={Airtel} alt="Airtel" />;
            case 'Jio':
                return <img src={Jio} alt="Jio" />;
            case 'Vodafone':
                return <img src={Vodafone_idea} alt="vodaidea" />;
            case 'DishTv':
                return <img src={Dishtv} alt="dishtv" />;
            case 'VideconApp':
                return <img src={Videocon} alt="Videocon" />;
            case 'Bsnl':
                return <img src={bsnl} alt="bsnl" />;
            case 'Tatasky':
                return <img src={TataSky} alt="Tatasky" />;
            case 'SunDirect':
                return <img src={SunDirect} alt="sundirect" />;


            // Add more cases for other providers
            default:
                return <img src="/default-logo.png" alt="Default" />;
        }
    }
    const handleClick = (index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


///payment details fetching



    return (
        <div className={styles.wrapper_accordion}>
          
            {/* {bankdata[0].BankDetails} */}
            <div className={styles.wrapper1}>
                <div className={styles.btn_group} style={{ height: "70px" }}>
                    <h2>All Plans</h2>
                </div>

                <div className={styles.wrapper_according}>
                    {
                        items ? (<>
                            {items.map((item, index) => (
                                <div key={index}>
                                    <div
                                        className={`${styles.accordion_item} ${styles.index === activeIndex ? 'active' : ''}`}
                                        onClick={() => handleClick(index)}
                                    >
                                        <div className={styles.accordion_header}>
                                            {index === activeIndex ? (
                                                <img src={minus} alt="" className='plus' />

                                            ) : (
                                                <img src={plus} alt="" className='plus' />


                                            )}
                                            {item.title}

                                        </div>

                                        {index === activeIndex && (
                                            <div className={styles.accordion_content} >
                                                {item.content.map((div, divIndex) => (
                                                    <div key={divIndex}>{div}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

<> <Modal show={show } onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>scan QR or Pay</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
 {/* { bankdata ?(<> {
    bankdata.map((data, index) =>(
        
        <div key={index}>
        {data.BankDetails.branchName}</div>
    )
 }</>):(<></>) */}
<Pay/>
                        
                      
                    
                <form onSubmit={handleSubmit} className={styles.container} encType="multipart/form-data">
                                        <div >
                                      
                                           
                                        <label className={styles.form}>
                                                Payment Reciept:
                                                <input type="file" name="paymentReciept" accept=".pdf,.jpg,.png" onChange={(e) => handleFileChange(e, "paymentReciept")} />
                                            </label>
                                           
                                           
                                             
                
                                            <label className={styles.form}>
                                                Amount
                                                <input
                                                    type="text"
                                                    placeholder='Enter Your amount'
                                                    name="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    required
                                                />
                                            </label>
                
                                            <label className={styles.form}>
                                               Transaction Id
                                                <input
                                                    type="text"
                                                    name="transactionid"
                                                    placeholder='transaction id'
                                                    value={transactionid}
                                                    onChange={(e) => setTransactionid(e.target.value)}
                                                    required
                                                />
                                            </label>
                                            <div className={`text-center ${styles.form}`}>
                                                <button type="submit" className={styles.btn}>{
                                                    loading ? 'Loading..' : 'Sumbit'
                                                }</button>
                                            </div>
                
                                          
                                        </div>
                                    </form>
                   
                
                    </Modal.Body>
                 
                  </Modal></>
                        </>) : (<></>)
                    }

                </div>

                

            </div>
            
              
            

           
 
  
        </div>
    );
};

export default Accordion;
