import React, {useState} from 'react';
import  "./adminnav.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/UserContext';
import logo from "../../../src/assets/images/logo11.png"


const AdminNav = () => {
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const navigate = useNavigate;
  const { logoutAdmin } = useAuth();

  const toggleNavbarVisibility = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };
  
  const handleAdminLogout = () => {
    // Call the logoutAdmin function to log out the admin
    console.log("loggedout")
    // localStorage.removeItem('adminToken');
    // setIsLoggedIn(false);
    // setForceRender(prevState => !prevState);
    logoutAdmin();
    navigate("/adminlogin")

    // Perform any additional actions after logout if needed
  };

  const closeNavbar = () => {
    setIsNavbarExpanded(false);

  };

  return (
    // <div className={styles.adminnav_wrapper}>
    //     {/* <div className={styles.adminnav_items}>User-List</div>
    //     <div className={styles.adminnav_items}>Add-plans</div>
    //     <div className={styles.adminnav_items} onClick={() =>(navigate("/bank-master"))}>Bank Master</div>
    //     <div className={styles.adminnav_items}  onClick={() =>(navigate("/bank-master"))}>Transictions</div> */} 
    //      <div className={`${styles.topnav} ${responsive ? styles.responsive : ''}`} id="myTopnav">
     
    //   <a href="#news" className={styles.navLink}>
    //     News
    //   </a>
    //   <a href="#contact" className={styles.navLink}>
    //     Contact
    //   </a>
    //   <a href="#about" className={styles.navLink}>
    //     About
    //   </a>
     

    //   </div>
    //   <a href="javascript:void(0);" className={`${styles.icon} ${styles.navLink}`} onClick={toggleResponsive}>
    //   tt
    //   </a>
       
    
    // </div>
    <header id="navbar3">
    <nav1 className="navbar-container3 container3">
      <a href="/" className="home-link">
        <div className="">  <img src={logo} alt=""  style={{"width": "60px"}}/></div>
      
      </a>
      <button
        type="button"
        id="navbar-toggle"
        aria-controls="navbar-menu"
        aria-label="Toggle menu"
        aria-expanded={isNavbarExpanded}
        onClick={toggleNavbarVisibility}
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <div
        id="navbar-menu"
        aria-labelledby="navbar-toggle"
        className={isNavbarExpanded ? 'expanded' : ''}
        onClick={closeNavbar}
      >
        <ul className="navbar-links">
          <li className="navbar-item"><a className="navbar-link" href="/adminhomw">Add Plans</a></li>
          <li className="navbar-item"><a className="navbar-link" href="/users">User's List</a></li>
          <li className="navbar-item"><a className="navbar-link" href="/bank-master">Bank Master</a></li>
          <li className="navbar-item"><a className="navbar-link" href="/payments" >Transictions</a></li>
          <li className="navbar-item"><button className="navbar-link" href="/payments" onClick={handleAdminLogout}>logout</button></li>
        </ul>
      </div>
    </nav1>
  </header>
  )
}

export default AdminNav