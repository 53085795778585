import React, { useEffect, useState } from 'react';
import styles from "../../Component/cardCss/Test.module.css"




const Pay = () => {

    const [bankdata, setBankdata] = useState("");


    useEffect(() => {
        fetch('https://dotmoney-bcknd.onrender.com/getUserPaymentDetails')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok.');
            }
            return response.json();
          })
          .then((data) => {
            console.log("Fetched Data:", data);
            console.log("Bank Account Number:", data?.QR_and_bankDetails[0]?.BankDetails?.accountNumber);
            setBankdata(data.QR_and_bankDetails);
      
            if (data.QR_and_bankDetails && data.QR_and_bankDetails.PaymentQR) {
            
              
            
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []);

      console.log(">>>>",bankdata)
      
  return (
    <div>  { Array.isArray(bankdata) && bankdata.length > 0 ? (
        <>
          <div className={styles.qr_up}>
            <div className="content">
              <div>Account No.: {bankdata[0].BankDetails.accountNumber}</div>
              <div>Account Holder name: {bankdata[0].BankDetails.accountHoldersName}</div>
              <div>Bank Name: {bankdata[0].BankDetails.bankName}</div>
              <div>Branch: {bankdata[0].BankDetails.branchName}</div>
              <div>Ifsc code:{bankdata[0].BankDetails.IFSCCode}</div>
            </div>
            <div>{bankdata[0].BankDetails.bankName}</div>
            <div >Amount To Pay 299</div>
            <div>
    <img src={`https://dotmoney-bcknd.onrender.com/${bankdata[1].PaymentQR}`} alt="Payment QR Code" />
</div>
          </div>
        </>
      ) : (
        <>Loading</>
      )}</div>
  )
}

export default Pay