import React, { useEffect, useState, useCallback } from 'react';
import './header1.css';
import styles from './header.module.css';
import './header1.css';

import Logo from './../../assets/images/logo11.png';
import userprofile from './../../assets/icons/userprofile.png';
import customerService from './../../assets/icons/customerService.png';
import logoutIcon from './../../assets/icons/logoutIcon.png';
import username from './../../assets/icons/username.png';
import telephone from './../../assets/icons/telephone.png';
import history from './../../assets/icons/history.png';
import support from './../../assets/images/support.png';
import notification from './../../assets/images/notification.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { blue } from '@mui/material/colors';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import menu from "../../assets/icons/menu.png"
import { useAuth } from '../../Context/UserContext';

import cross from "../../assets/icons/close.png"




const Header = () => {

  const navigate = useNavigate()
  const { adminToken, userToken } = useAuth();
  const isAdmin = adminToken !== null;
  const isUserLoggedIn = userToken !== null;
  const { logoutAdmin } = useAuth();
  const { logoutUser } = useAuth();
  const { user } = useAuth()
  const [isLoggedIn, setIsLoggedIn] = useState("false");
  const [forceRender, setForceRender] = useState(false);



  console.log(user);

  const handleUserLogout = () => {
    console.log("Logging out user...")
    // localStorage.removeItem('userToken');
    // console.log("userloggedout")
    // setForceRender(prevState => !prevState);
    logoutUser()
    setIsLoggedIn(false);

    // Additional logic after user logout

  };

  const handleAdminLogout = () => {
    // Call the logoutAdmin function to log out the admin
    console.log("loggedout")
    // localStorage.removeItem('adminToken');
    // setIsLoggedIn(false);
    // setForceRender(prevState => !prevState);
    logoutAdmin();
    navigate("/adminlogin")

    // Perform any additional actions after logout if needed
  };

  useEffect(() => {
    setIsLoggedIn(isAdmin || isUserLoggedIn);
  }, [isAdmin, isUserLoggedIn]);

  const handellogin = () => {
    console.log("tt")
    navigate("/userlogin");

  }



  return (
    <div className={styles.navbar}
    >
      <div className={styles.navbar_logo}>
        <img src={Logo} alt="logo" />
      </div>
      <div className={`${styles.navbar_links} `}>

        <ul>

          {adminToken && (
            <div className={styles.adminlogout}>
              <div className={styles.adminnav} onClick={() => (navigate("/adminhome"))} >Add plans</div>

              <div className={styles.adminnav} onClick={() => (navigate("/payments"))} >Transictions</div>
              <div className={styles.adminnav} onClick={() => (navigate("/users"))}>Users</div>
              <Button onClick={handleAdminLogout} className={styles.btn}>Logout</Button>
            </div>
            // Render content for admin
          )}
          {userToken  && (
            <>
              <nav>
                <ul>
                  <li>
                    <img src={customerService} alt="" className={styles.img - 1} />
                  </li>
                  <li className='profileh'>
                    <img src={userprofile} alt="" className={styles.img - 1} style={{ fontSize: "50px" }} />
                    <ul>
                      <li className="sub-item">
                        <span className="material-icons-outlined">
                          <span ><img src={username} alt="" className={styles.img - 1} /> </span>&emsp;
                          {user?.name}
                        </span>

                      </li>
                      <li className="sub-item">
                        <span className="material-icons-outlined">
                          <span ><img src={telephone} alt="" className={styles.img - 1} /></span>&emsp;
                          {user?.mobileNo}
                        </span>

                      </li>
                      <li className="sub-item">
                        <span className="material-icons-outlined">
                          <span ><img src={history} alt="" className={styles.img - 1} /></span >&emsp;
                          Order History</span>

                      </li>
                      <li className="sub-item" onClick={handleUserLogout} >
                        <span className="material-icons-outlined" >
                          <span ><img src={logoutIcon} alt="" className={styles.img - 1} /></span>&emsp;
                          logout </span>
                        {/* <p>Logout</p> */}
                      </li>
                    </ul>

                  </li>
                </ul>

              </nav>
            </>
          )}
          {!userToken && !adminToken && (
            <>
              <div>
                <Button className={styles.btn} onClick={() => (navigate("/usersignup"))}>Sign Up</Button>

              </div>&nbsp;&nbsp;&nbsp;
              <div onClick={() => (navigate("/userlogin"))}>
                <Button className={styles.btn}>Login</Button>

              </div>




            </>
          )}

        </ul>
      </div>



    </div>
  );
};

export default Header;