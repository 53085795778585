import React, { useEffect, useState } from "react";
import styles from './payment.module.css';
import { useAuth } from "../../Context/UserContext";

export default function Payments() {
    const [transactions, setTransactions] = useState([]);
    const { adminToken } = useAuth();


    useEffect(() => {
        // Simulated fetch request to backend
        // Replace this with your actual fetch call to get transaction data from the backend
        const fetchData = async () => {
            try {
                // Perform fetch request
                const response = await fetch('YOUR_BACKEND_ENDPOINT');
                const data = await response.json();
                setTransactions(data); // Assuming data is an array of transactions
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    //fetching bankmaster data 
const fetchData = async () => {
    try {
      const url = 'https://dotmoney-bcknd.onrender.com/GetpaymentDetailes';
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${adminToken}`, // Replace with your actual token
        // Any other headers you need can be added here
      };

      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const [bankdata, setBankdata] = useState(null);

  useEffect(() => {
    const fetchDataFromEndpoint = async () => {
      const fetchedData = await fetchData();
      // setBankName(fetchedData);
      console.log(bankdata)
    };

    fetchDataFromEndpoint();
  }, []);


    return (
        <div className='wrapper-accordion' style={{ width: "10" }}>
            <h1 className={styles.heading}>Transactions</h1>
            <div className={styles.wrapper}>
                <table className="table">
                    <tbody className={styles.tablebody}>
                        <tr>
                            <th>Name</th>
                            <th>Phone No.</th>
                            <th>Plan Name</th>
                            <th>Plan Price</th>
                            <th>Payment Status</th>
                        </tr>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.name}</td>
                                <td>{transaction.phone}</td>
                                <td>{transaction.planName}</td>
                                <td>{transaction.planPrice}</td>
                                <td>{transaction.paymentStatus}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
