import "./App.css";

import Layouts from "./Component/Layouts/Layouts";
import { AuthProvider } from './Context/UserContext';
function App() {
  return (
    <>
      <AuthProvider>
        < Layouts />
      </AuthProvider>


    </>


  )
}

export default App;
