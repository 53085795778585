import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './../Mainpages/Home';
import AdminHome from '../Mainpages/Admin_Pages/AdminHome';
import About from './../Mainpages/About';
import Contact from './../Mainpages/Contact';
// import Login from '../Mainpages/Login';

// import Sign from "../Mainpages/signIn/Sigin";
import Demologin from '../Mainpages/Demologin';
import Test from '../Mainpages/Test';
import GetUser from "../Mainpages/Admin_Pages/Getusers"
import Payments from '../Mainpages/Admin_Pages/Payments';
import AboutUs from '../Mainpages/AboutUs';
import AdminLogin from "../Mainpages/Admin_Pages/login";
import { useAuth } from '../Context/UserContext';
import { useContext } from 'react';

import UserSignup from '../Mainpages/userpages/UserSignup';
import Userlogin from '../Mainpages/userpages/Userlogin';
import Orderhistory from '../Mainpages/userpages/Orderhistory';
import Bank_master from '../Mainpages/Admin_Pages/Bank_master';
// import Testt from "../Component/Headers/Testt"

// const Routers = () => {
//   return (
//     <>
//       <Routes>
//         <Route path={`${process.env.PUBLIC_URL}/`} element={<Navigate to='Home' />} />
//         <Route path={`${process.env.PUBLIC_URL}/home`} element={<Home />} />
//         <Route path={`${process.env.PUBLIC_URL}/adminhome`} element={<AdminHome />} />
//         {/* <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} /> */}
//         {/* <Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} /> */}
//         {/* <Route path='/login' element={<Login/>}/>  */}
//         {/* <Route path='/sign' element={<Sign/>}/>  */}

//         <Route path={`${process.env.PUBLIC_URL}/demo`} element={<Demologin />} />
//         <Route path={`${process.env.PUBLIC_URL}/test`} element={<Test />} />
//         <Route path={`${process.env.PUBLIC_URL}/users`} element={<GetUser />} />
//         {/* <Route path={`${process.env.PUBLIC_URL}/modal`} element={<Modal />} /> */}
//         <Route path={`${process.env.PUBLIC_URL}/payments`} element={<Payments />} />
//         <Route path={`${process.env.PUBLIC_URL}/aboutus`} element={<AboutUs />} />
//         {/* <Route path={`${process.env.PUBLIC_URL}/testt`} element={<Testt/>}/> */}

//       </Routes>

//     </>
//   );
// }



// const UserRoutes = () => {
//   return (
//     <Routes>
//       <Route path={`${process.env.PUBLIC_URL}/`} element={<Navigate to='Home' />} />
//       <Route path={`${process.env.PUBLIC_URL}/home`} element={<Home />} />
//     </Routes>
//   )
// }




// const AdminRoutes = () => {
//   return (
//     <Routes>
//       <Route path={`${process.env.PUBLIC_URL}/adminhome`} element={<AdminHome />} />
//       <Route path={`${process.env.PUBLIC_URL}/users`} element={<GetUser />} />
//       <Route path={`${process.env.PUBLIC_URL}/payments`} element={<Payments />} />
//     </Routes>
//   )
// }

// const Routers = () => {

//   let isAdmin = false;

//   return (
//     <Routes>
//       {isAdmin ? <AdminRoutes /> : <UserRoutes />}

//     </Routes>

//   )
// }


const Routers = () => {


  const { adminToken } = useAuth();
  console.log(adminToken)
  const isAdmin = adminToken !== null;
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
      <Route path={`${process.env.PUBLIC_URL}/home`} element={<Home />} />
      <Route path={`${process.env.PUBLIC_URL}/adminlogin`} element={< AdminLogin />} />
      <Route path={`${process.env.PUBLIC_URL}/usersignup`} element={< UserSignup />} />
      <Route path={`${process.env.PUBLIC_URL}/userlogin`} element={< Userlogin />} />
      <Route path={`${process.env.PUBLIC_URL}/orderhistory`} element={< Orderhistory />} />
      <Route path={`${process.env.PUBLIC_URL}/test`} element={< Test />} />
      




      {isAdmin ? (
        <>
          <Route path={`${process.env.PUBLIC_URL}/adminhome`} element={<AdminHome />} />
          <Route path={`${process.env.PUBLIC_URL}/users`} element={<GetUser />} />
          <Route path={`${process.env.PUBLIC_URL}/payments`} element={<Payments />} />
          <Route path={`${process.env.PUBLIC_URL}/bank-master`} element={<Bank_master/>} />
        </>
      ) : (
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
      )}
    </Routes>
  );
};

export default Routers;