import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [adminToken, setAdminToken] = useState(null);

  // Simulated login functions (replace these with your actual login logic)
  const loginUser = (userData, token) => {
    setUser(userData);
    setUserToken(token);
    localStorage.setItem('userToken', token);
  };

  const loginAdmin = (adminData, token) => {
    setAdmin(adminData);
    setAdminToken(token);
    localStorage.setItem('adminToken', token);
  };

  const logoutUser = () => {
    setUser(null);
    setUserToken(null);
    localStorage.removeItem('userToken');
  };

  const logoutAdmin = () => {
    setAdmin(null);
    setAdminToken(null);
    localStorage.removeItem('adminToken');
  };

  // Check localStorage for tokens on initial load
  useEffect(() => {
    const storedUserToken = localStorage.getItem('userToken');
    const storedAdminToken = localStorage.getItem('adminToken');

    if (storedUserToken) {
      setUserToken(storedUserToken);
    }
    if (storedAdminToken) {
      setAdminToken(storedAdminToken);
    }
  }, []);

  const contextValue = {
    user,
    admin,
    userToken,
    adminToken: adminToken || null, // Provide a default value of null if adminToken is undefined
    loginUser,
    loginAdmin,
    logoutUser,
    logoutAdmin,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
