import React, { useEffect, useState } from 'react';
import styles from './../../Component/cardCss/Test.module.css';
// import './Accordion.css';
// ./../../Component/cardCss/test';
import plus from '../../assets/images/add.png';
import minus from '../../assets/images/minus.png';
import Airtel from '../../assets/images/air.png';
import Jio from '../../assets/images/jio1.png';
import Vodafone_idea from '../../assets/images/vodaidea.png';
import bsnl from '../../assets/images/bsnl.jpg';
import Dishtv from '../../assets/images/dishtv1.webp';
import Videocon from '../../assets/images/videocon.png';
import TataSky from '../../assets/images/tatasky.jpg';
import SunDirect from '../../assets/images/sun.png';
import Modal from '@mui/material/Modal';
import toast, { Toaster } from "react-hot-toast";
import "../../Mainpages/addvoucher.css"
import cross from "../../assets/icons/close.png"
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'





function AdminHome() {

  const [activeIndex, setActiveIndex] = useState(null);
  const groupedItems = {};
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    Title: '',
    Provider: 'Airtel',
    Amount: '',
    Description: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();

    // Prepare the data to send to the server
    const planData = {
      title: formData.Title,
      provider: formData.Provider,
      amount: formData.Amount,
      description: formData.Description,
    };

    // Make an API call to send the plan data to the server
    fetch('https://dotmoney-bcknd.onrender.com/plan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ2MTYwZWM0MGEyMTU5OWYyYmI3NWYiLCJpYXQiOjE2OTkwOTU3NTksImV4cCI6MTcwMjExOTc1OX0.SmkEu9cZatrSr67mEebI5KGWWmmBGNpKCtsDyIhJ0oA'
      },
      body: JSON.stringify(planData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("success", response)
          setFormData({
            Title: '',
            Provider: 'Airtel',
            Amount: '',
            Description: '',
          });
          showToast();
          handleVoucherClose()

          setIsLoading(false)

          // Plan added successfully, you can handle the response here
        } else {
          // Handle errors or display an error message
        }
      })
      .catch((error) => {
        // Handle network errors
      });
  };

  const showToast = () => {
    toast("Plan added succesfully ");
  };

  const [openVoucher, setOpenVoucher] = React.useState(false);

  const handleVoucherOpen = () => {
    setOpenVoucher(true);
  };
  const handleVoucherClose = () => {
    setOpenVoucher(false);
  };

  useEffect(() => {
    // Define the URL of the endpoint
    const endpointURL = 'https://dotmoney-bcknd.onrender.com/getPlan';

    // Make a GET request to the endpoint
    fetch(endpointURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        // Update the state with the fetched data
        setData(responseData.result);
        responseData.result.forEach((product) => {
          const provider = product.provider;
          if (!groupedItems[provider]) {
            groupedItems[provider] = [];
          }
          if (!groupedItems[provider].some((item) => item._id === product._id)) {
            // Checking if the item with the same ID already exists in the group
            groupedItems[provider].push(product);
          }

        });



        console.log(responseData)

        setItems(Object.keys(groupedItems).map((provider, index) => ({
          title: provider,
          content: groupedItems[provider].map((product, subIndex) => (
            <div key={subIndex}>
              <div className={styles.wrapper}>
                <div className={styles.left}>
                  <div className={styles.logo} key={subIndex}>
                    {renderProviderLogo(product.provider)}
                  </div>
                </div>
                <div className={styles.center} key={subIndex}>
                  <div className={styles.up}>
                    {product.title}
                  </div>
                  <div className={styles.down} key={subIndex}> {product.description}</div>

                </div>
                <div className={styles.right} key={subIndex}>
                  <div>
                    ₹ {product.amount}
                  </div>
                  <button className={styles.button_buy} role='button' onClick={() => handleDeletePlan(product._id)}>Delete Plan</button>

                </div>
                <span className={styles.top}></span>
                <span className={styles.right1}></span>
                <span className={styles.button}></span>
                <span classN={styles.left1}></span>
              </div>
            </div>
          )),
        })))
        setIsLoading(false);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  function renderProviderLogo(provider) {
    switch (provider) {
      case 'Airtel':
        return <img src={Airtel} alt="Airtel" />;
      case 'Jio':
        return <img src={Jio} alt="Jio" />;
      case 'Vodafone':
        return <img src={Vodafone_idea} alt="vodaidea" />;
      case 'DishTv':
        return <img src={Dishtv} alt="dishtv" />;
      case 'VideconApp':
        return <img src={Videocon} alt="Videocon" />;
      case 'Bsnl':
        return <img src={bsnl} alt="bsnl" />;
      case 'Tatasky':
        return <img src={TataSky} alt="Tatasky" />;
      case 'SunDirect':
        return <img src={SunDirect} alt="sundirect" />;


      // Add more cases for other providers
      default:
        return <img src="/default-logo.png" alt="Default" />;
    }
  }
  const handleClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  

  // delete plan
  function handleDeletePlan(planId) {
    // Make a DELETE request to the endpoint with the plan ID in the URL
    fetch(`https://dotmoney-bcknd.onrender.com/deletePlan/${planId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          // Plan was deleted successfully
          console.log("Plan deleted successfully");
          // Update the UI to reflect the deleted plan
          setItems(items.filter((item) => item.id !== planId));
        } else {
          // There was an error deleting the plan
          console.error("Error deleting plan:", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error deleting plan:", error);
      });
  }
  useEffect(() => {
    // Re-render the component when the items state changes
    console.log('Items updated');
  }, [items]);

  return (
    <div className='wrapper-accordion'>
      <div className='wrapperadmin'>
        <div className={styles.adminwrap} style={{ height: "70px" }}>
          <h2> Plans</h2>
          <div onClick={handleVoucherOpen} className={styles.button_addplan}>Add Plan</div>
          <Modal
            keepMounted
            open={openVoucher}
            onClose={handleVoucherClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <>
              <div><Toaster /></div>

              <section>

                <div className="container">
                  <div className="row">
                    <div className=" col-xl-6 col-lg-6 col-sm-12 col-md-12 mx-auto form1">
                      <div className='row justify-content-center' style={{ "background-color": "white" }}>
                        <div className={styles.addvclose} onClick={handleVoucherClose}><img src={cross} /></div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <h1> Add Voucher</h1>

                          </div>

                          <label>
                            Title:
                            <input
                              type="text"
                              placeholder='Name'
                              name="Title"
                              value={formData.Title}
                              onChange={handleInputChange}
                              required
                            />
                          </label>
                          <label>
                            Provider:
                            <select name="Provider" value={formData.Provider} onChange={handleInputChange}>
                              <option value="Vodafone">Vodafone-Idea</option>
                              <option value="Airtel">Airtel</option>
                              <option value="Bsnl">Bsnl</option>
                              <option value="Jio">Jio</option>
                              <option value="DishTv">DishTv</option>
                              <option value="TataSky">TataSky</option>
                              <option value="VideconApp">VideconApp</option>
                              <option value="SunDirect">SunDirect</option>
                              <option value="JioPosLite">JioPosLite</option>
                              <option value="DishTv">DishTv</option>
                              <option value="D2H">D2H Pay</option>
                            </select>
                          </label>

                          <label>
                            Amount:
                            <input
                              type="text"
                              name="Amount"
                              value={formData.Amount}
                              onChange={handleInputChange}
                              required
                            />
                          </label>

                          <label>
                            Description:
                            <input
                              type="text"
                              name="Description"
                              value={formData.Description}
                              onChange={handleInputChange}
                              required
                            />
                          </label>

                          <div className="text-center">
                            {
                              isLoading ? (<div><button class="button" type="submit" role="button">loading..</button></div>) : (<button class="button" type="submit" role="button">Add</button>
                              )
                            }
                            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                          </div>
                        </form>

                        {/* <button onClick={showToast}>Click me to show a toast</button> */}

                      </div>
                    </div>
                  </div>
                </div>

              </section>

            </>
          </Modal>
        </div>

        <div className={styles.wrapper_accordion}>
          <div className={styles.wrapper1}>


            <div className={styles.wrapper_according}>


              {isLoading ? (<div className={styles.loading}>Loading..</div>) : (
                items.map((item, index) => (
                  <div key={index}>
                    <div
                      className={`${styles.accordion_item} ${styles.index === activeIndex ? 'active' : ''}`}
                      onClick={() => handleClick(index)}
                    >
                      <div className={styles.accordion_header}>
                        {index === activeIndex ? (
                          <img src={minus} alt="" className='plus' />

                        ) : (
                          <img src={plus} alt="" className='plus' />


                        )}
                        {item.title}

                      </div>

                      {index === activeIndex && (
                        <div className={styles.accordion_content} >
                          {item.content.map((div, divIndex) => (
                            <div key={divIndex}>{div}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )

              }


            </div>

          </div>
        </div>
      </div>
    </div >
  );
}

export default AdminHome;
