import React from 'react';
import Routers from '../../Routers/Routers';
import Headers from './../../Component/Headers/Header';
import Footers from './../../Component/Footers/Footers';
import { useAuth } from '../../Context/UserContext';
import AdminNav from '../adminnav/AdminNav';



const Layouts = () => {
  const { user, admin } = useAuth();

  // Check if the user is an admin or a regular user
  const isAdmin = admin !== null;
  const isUser = user !== null;

  return (
    <>
   
      {isAdmin ? (
        // Render admin-specific content
        <>
        <AdminNav/>
          <Routers />
          <Footers />
        </>
      ) : isUser ? (
        // Render regular user-specific content using UserLayout component
        <>
           <Headers />
        <Routers />
        <Footers />
      </>
      ) : (
        // Render default content if neither admin nor user is authenticated
        <>
           <Headers />
          <Routers />
          <Footers />
        </>
      )}
    </>
  );
};

export default Layouts;
