import React, { useEffect, useState } from 'react';

import { useAuth } from '../../Context/UserContext';


const Orderhistory = () => {

    const [data, setData] = useState([]);
    const { userToken } = useAuth();
    console.log(userToken)

    useEffect(() => {
        const fetchOrder = async () => {
            const token = userToken;
            try {
                const response = await fetch("https://dotmoney-bcknd.onrender.com/userOrderPlanHistory", {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error("error")
                }
                const responseData = await response.json();

                console.log(responseData)
                setData(responseData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchOrder();
    }, [])





    return (
        <div>Orderhistory</div>
    )
}

export default Orderhistory