import React, { useState } from 'react';
import styles from './login.module.css';
import logo from "../../assets/images/logo11.png";
import { useAuth } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const { loginAdmin } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        mobileNo: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted

        try {
            const response = await fetch('https://dotmoney-bcknd.onrender.com/adminLogin', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data)
                const adminData = data.result; // Assuming result contains admin data
                const adminToken = data.result.token; // Assuming the token is inside the result object

                loginAdmin(adminData, adminToken);
                navigate("/adminhome")
            } else {
                // Handle response status other than 200
                console.error('Failed to log in:', response.statusText);
            }
        } catch (error) {
            // Handle fetch error
            console.error('Fetch error:', error);
        } finally {
            setLoading(false); // Set loading to false when the request is completed (success or failure)
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={` ${styles['col-md-2']}`}></div>
                <div className={`${styles['col-md-8']} ${styles.login_box}`}>
                    <div className={` ${styles.login_key}`}>
                        <img src={logo} alt="" />
                    </div>
                    <div className={` ${styles.login_title}`} styles={{ "color": "black" }}>
                        ADMIN PANEL
                    </div>
                    <div className={` ${styles.login_form}`}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles['form-group']}>
                                <label className={styles['form-control-label']}>Phone No.</label>
                                <input type="no." className={styles['form-control']} name="mobileNo"
                                    value={formData.mobileNo}
                                    onChange={handleInputChange} />
                            </div>
                            <div className={styles['form-group']}>
                                <label className={styles['form-control-label']}>Password</label>
                                <input type="password" className={styles['form-control']} name="password"
                                    value={formData.password}
                                    onChange={handleInputChange} />
                            </div>
                            <div className={` ${styles['loginbttm']}`}>
                                <div className={` ${styles['login-btm']} ${styles['login-text']}`}>
                                    {/* Error Message */}
                                </div>
                                <div className={`${styles['login_btm']} ${styles['login_button']}`}>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <button type="submit" className={`${styles['btn']} ${styles['btn-outline-primary']}`}>LOGIN</button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={`${styles['col-lg-3']} ${styles['col-md-2']}`}></div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
