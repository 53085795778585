import React, { useEffect, useState } from 'react';
import styles from "./users.module.css";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useAuth } from '../../Context/UserContext';


function DataFetchingComponent() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const fetchImageAsBlob = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw new Error('Failed to fetch image: ' + error.message);
    }
  };



  // useEffect(() => {
  //   // Define the URL of the endpoint for your backend API
  //   const endpointURL = 'http://localhost:7000/getuser';

  //   // Define your bearer token
  //   const bearerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ2MTYwZWM0MGEyMTU5OWYyYmI3NWYiLCJpYXQiOjE2OTkyNjkyMDYsImV4cCI6MTcwMjI5MzIwNn0.zBtIFmenRwd0w8n7DIvIRCzxD1HEmuKqADkQXVxAdjM';

  //   // Make a GET request to the backend API with the Authorization header
  //   fetch(endpointURL, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization': `Bearer ${bearerToken}`
  //     }
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((responseData) => {
  //       setData(responseData.result); // Store the fetched data
  //       console.log(responseData.result);
  //       console.log(responseData.result[0].document.aadhaarImage)

  //       setImages(images);
  //       console.log(images)
  //       setLoading(false); // Set loading to false
  //     })
  //     .catch((error) => {
  //       setError(error.message); // Set error if there's an issue
  //       setLoading(false); // Set loading to false
  //     });
  // }, []);
  const { adminToken } = useAuth();

  useEffect(() => {
    const endpointURL = 'https://dotmoney-bcknd.onrender.com/getUser';
    const bearerToken = adminToken

    const fetchData = async () => {
      try {
        const response = await fetch(endpointURL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${bearerToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log(responseData)
        setData(responseData.result)

        const newData = await Promise.all(
          responseData.result.map(async (item) => {
            const imageUrl = `https://dotmoney-bcknd.onrender.com/${item.documentImage}`;
            const blob = await fetchImageAsBlob(imageUrl);
            return { ...item, aadhaarImageBlob: blob };
          })
        );

        setData(newData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const style = {
    height: '600px',
    width: '600px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  // const createImageUrl = (filePathWithBinaryData) => {
  //   // Split the path to extract the binary data part (logic might vary based on the structure)
  //   const binaryData = filePathWithBinaryData.split('/')[1]; // Example logic, adjust based on actual structure

  //   // Decode the binary data if needed (e.g., base64 decoding)
  //   const decodedData = atob(binaryData); // Decode base64 data

  //   // Convert the decoded data to Uint8Array
  //   const uint8Array = new Uint8Array(decodedData.length);
  //   for (let i = 0; i < decodedData.length; i++) {
  //     uint8Array[i] = decodedData.charCodeAt(i);
  //   }

  //   // Create a Blob from the Uint8Array
  //   const blob = new Blob([uint8Array], { type: 'image/jpeg' }); // Adjust type if different image format

  //   // Create a URL for the Blob
  //   const imageUrl = URL.createObjectURL(blob);

  //   // Assuming 'baseUrl' is the base URL for accessing the backend server
  //   const baseUrl = 'https://dotmoney-bcknd.onrender.com'; // Replace with your actual backend URL

  //   // Concatenate the base URL and the created image URL
  //   return `${baseUrl}/${imageUrl}`;
  // };


  return (
    <div className={styles.wrapper}>
      <div>
        <h2>User Data</h2>
        <div className={styles.table}>
          <table>
            <thead className={styles.table_header}>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Date of Birth</th>
                <th>Documents</th>
              </tr>
            </thead>
            <tbody className={styles.table_body}>{
              data? (<>    {data.map((item, index) => (
                <tr key={index} className={styles.table_row}>
                  <td>{index}</td>
                  <td>{item.name}</td>
                  <td>{item.mobileNo}</td>
                  <td>{new Date(item.DOB).toDateString()}</td>
                  <td>
                    <Button onClick={handleOpen}>View Document</Button>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <img src={`https://dotmoney-bcknd.onrender.com/${item.documentImage}`} alt="Aadhaar Image" className={styles.doc_img} />
                      </Box>

                    </Modal>


                  </td>
                </tr>
              ))}
</>): (<>test</>)
            }

            

            </tbody>
          </table>
        </div>
      </div>

    </div>
  );

}

export default DataFetchingComponent;
